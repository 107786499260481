import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import BottomBorder from "../bottomBorder";
import { getImageProps } from "helpers/getImgProps";
import * as styles from "./smoothie.module.scss";

const SmoothieBowls = ({ image, smoothieBowlsData }) => {
  const smoothieList = smoothieBowlsData?.products.map(
    (
      { image_url, description: name, product_ingredients: ingredients, price },
      index
    ) => {
      const ingredientList = ingredients?.map(({ name }, index) =>
        index === ingredients.length - 1
          ? `${name}`.toLowerCase()
          : `${name}, `.toLowerCase()
      );
      if (index % 2 !== 0) {
        return (
          <div key={index} className={styles.smoothieItem}>
            <div className={styles.details}>
              <p className={styles.name}>{name}</p>
              <p className={styles.price}>{price} amd</p>
              <p className={styles.ingredients}>{ingredientList}</p>
            </div>
            <div className={styles.image}>
              {image_url ? (
                <img src={image_url} alt="text" />
              ) : (
                <img src={placeholder} alt="placeholder" />
              )}
            </div>
          </div>
        );
      } else
        return (
          <div key={index} className={styles.smoothieItem}>
            <div className={styles.image}>
              {image_url ? (
                <img src={image_url} alt="text" />
              ) : (
                <img src={placeholder} alt="placeholder" />
              )}
            </div>
            <div className={styles.details}>
              <p className={styles.name}>{name}</p>
              <p className={styles.price}>{price} amd</p>
              <p className={styles.ingredients}>{ingredientList}</p>
            </div>
          </div>
        );
    }
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleImage}>
        <GatsbyImage {...getImageProps(image)} />
      </div>
      <div className={styles.title}>
        {smoothieBowlsData?.name}
        <BottomBorder />
      </div>
      <div className={styles.smoothieItemList}>{smoothieList}</div>
    </div>
  );
};

export default SmoothieBowls;
