// extracted by mini-css-extract-plugin
export var image = "wraps-module--image--eec4c";
export var ingredients = "wraps-module--ingredients--2cb28";
export var kcal = "wraps-module--kcal--7c944";
export var name = "wraps-module--name--b504a";
export var price = "wraps-module--price--7cd55";
export var singleWrap = "wraps-module--singleWrap--d72c1";
export var title = "wraps-module--title--923ef";
export var wrapImg = "wraps-module--wrapImg--e08bd";
export var wrapList = "wraps-module--wrapList--5c655";
export var wrapText = "wraps-module--wrapText--146ec";
export var wrapper = "wraps-module--wrapper--67ff0";