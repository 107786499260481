import React from "react";
import { Col } from "react-bootstrap";
import BottomBorder from "../bottomBorder";
import placeholder from "./../../../images/placeholder.png";
import * as styles from "./products.module.scss";

const Products = ({ data }) => {
  const productList = data.products.map(
    (
      {
        image_url: image,
        cal: kcal,
        description: name,
        product_ingredients: ingredients,
        price,
      },
      index
    ) => {
      const ingredientList = ingredients?.map(({ name }, index) =>
        index === ingredients.length - 1
          ? `${name}`.toLowerCase()
          : `${name}, `.toLowerCase()
      );
      return (
        <Col md={6} xl={4} key={index}>
          <div className={styles.singleProduct}>
            <div className={styles.image}>
              {image ? (
                <img src={image} alt="text" />
              ) : (
                <img src={placeholder} alt="placeholder" />
              )}
            </div>
            <p className={styles.name}>{name}</p>
            {ingredients && (
              <p className={styles.ingredients}>{ingredientList}</p>
            )}

            <div className={styles.details}>
              <p className={styles.price}>{price} amd</p>
              {kcal && <p className={styles.cal}>{kcal} kcal</p>}
            </div>
          </div>
        </Col>
      );
    }
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {data.name}
        <BottomBorder />
      </div>
      <div className={styles.productList}>{productList}</div>
    </div>
  );
};

export default Products;
