import React from "react";
import { Col } from "react-bootstrap";
import BottomBorder from "../bottomBorder";
import placeholder from "./../../../images/placeholder.png";
import * as styles from "./wraps.module.scss";

const Wraps = ({ wraps }) => {
  const wrapList = wraps?.products.map(
    (
      {
        image_url: image,
        cal: kcal,
        description: name,
        product_ingredients: ingredients,
        price,
      },
      index
    ) => {
      const ingredientList = ingredients?.map(({ name }, index) =>
        index === ingredients.length - 1
          ? `${name}`.toLowerCase()
          : `${name}, `.toLowerCase()
      );
      return (
        <Col xl={4} md={6} sm={12} key={index}>
          <div className={styles.singleWrap}>
            <div className={styles.wrapImg}>
              <div className={styles.image}>
                {image ? (
                  <img src={image} alt="text" />
                ) : (
                  <img src={placeholder} alt="placeholder" />
                )}
              </div>
            </div>
            <div className={styles.wrapText}>
              <p className={styles.kcal}>{kcal} kcal</p>
              <p className={styles.name}>{name}</p>
              <p className={styles.ingredients}>{ingredientList}</p>
              <p className={styles.price}>{price} amd</p>
            </div>
          </div>
        </Col>
      );
    }
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {wraps?.name}
        <BottomBorder />
      </div>
      <div className={styles.wrapList}>{wrapList}</div>
    </div>
  );
};

export default Wraps;
