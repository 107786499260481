// extracted by mini-css-extract-plugin
export var bgBottom = "bowls-module--bgBottom--b958c";
export var bowlImg = "bowls-module--bowlImg--ce863";
export var bowls = "bowls-module--bowls--a6889";
export var bowlsWrapper = "bowls-module--bowlsWrapper--9d8e0";
export var cal = "bowls-module--cal--c0284";
export var img = "bowls-module--img--f6a34";
export var imgPart = "bowls-module--imgPart--cd9af";
export var ingredients = "bowls-module--ingredients--4f7e8";
export var mobile = "bowls-module--mobile--f37a1";
export var name = "bowls-module--name--70c1e";
export var price = "bowls-module--price--aa0e1";
export var productDetails = "bowls-module--productDetails--4456c";
export var singleBowl = "bowls-module--singleBowl--8d81c";
export var title = "bowls-module--title--ea659";
export var vegan = "bowls-module--vegan--a2508";
export var web = "bowls-module--web--0c09b";