// extracted by mini-css-extract-plugin
export var cal = "products-module--cal--43b66";
export var details = "products-module--details--248ac";
export var image = "products-module--image--fc482";
export var ingredients = "products-module--ingredients--4092a";
export var name = "products-module--name--cf5b1";
export var price = "products-module--price--6a8b7";
export var productList = "products-module--productList--63523";
export var singleProduct = "products-module--singleProduct--274ab";
export var title = "products-module--title--155b8";
export var wrapper = "products-module--wrapper--986a8";