import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import MenuComponents from "components/menuComponents";
import { getMenuPageProps } from "helpers/getMenuPageProps";

const Menu = ({ data }) => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "eatn’act:  balanced food restaurant" }} />
      <MenuComponents {...getMenuPageProps(data)} />
    </Layout>
  );
};

export const query = graphql`
  query productsFromRds {
    allProductsFromRds {
      nodes {
        id
        name
        icon
        products {
          cal
          description
          discount
          id
          image_url
          is_add_on
          is_highlighted
          name
          price
          status
          product_ingredients {
            name
          }
        }
      }
    }
    strapiMenu {
      Bowls {
        veganImg {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        bowlImg {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        bgBottom {
          localFile {
            url
          }
        }
      }
      smoothieBowls {
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Menu;
