// extracted by mini-css-extract-plugin
export var details = "smoothie-module--details--5abc1";
export var image = "smoothie-module--image--acd03";
export var ingredients = "smoothie-module--ingredients--3221c";
export var name = "smoothie-module--name--6294b";
export var price = "smoothie-module--price--a5edb";
export var smoothieItem = "smoothie-module--smoothieItem--866ef";
export var smoothieItemList = "smoothie-module--smoothieItemList--a54d9";
export var title = "smoothie-module--title--00aba";
export var titleImage = "smoothie-module--titleImage--9bc4d";
export var wrapper = "smoothie-module--wrapper--ab20a";