import React from "react";
import Bowls from "./bowls";
import Wraps from "./wraps";
import SmoothieBowls from "./smoothieBowls";
import Products from "./products";

const MenuComponents = ({ bowls, smoothieBowls, nodes }) => {
  const display = nodes.map((data, index) => {
    switch (data.name) {
      case "Bowls":
        return <Bowls {...bowls} bowlsList={data} key={index} />;

      case "Wraps":
        return <Wraps wraps={data} key={index} />;

      case "Smoothie bowls":
        return (
          <SmoothieBowls
            {...smoothieBowls}
            smoothieBowlsData={data}
            key={index}
          />
        );

      default:
        return <Products data={data} key={index} />;
    }
  });
  return <>{display}</>;
};

export default MenuComponents;
